import { React, useEffect } from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr, Hidden } from "@material-ui/core"
import { graphql, navigate } from "gatsby"

import {
  Header,
  HeroBanner,
  MarketingCTA,
  CenteredMarketingCTA,
  Footer,
  CartProvider,
  ProductLine,
  Grid,
  SEO,
} from "../../../components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"

import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import "../global_styles/global.css"

addLocaleData([...locale_en, ...locale_es])

const IndexPage = ({ data, pageContext }) => {
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)
  const slideData = [
    {
      titleText: data.content.edges[0].node.heroBanner.slideOne.title,
      copyText:
        data.content.edges[0].node.heroBanner.slideOne.description.description,
      imageSrc: data.content.edges[0].node.heroBanner.slideOne.image.fluid,
      videoSrc: data.content.edges[0].node.heroBanner.slideOne.image.file.url,
      mediaType: data.content.edges[0].node.heroBanner.slideOne.mediaType,
    },
  ]
  const centeredMarketingCtaData1 = {
    titleText: data.content.edges[0].node.centeredMcta1.title,
    copyText: data.content.edges[0].node.centeredMcta1.content.content,
  }
  const centeredMarketingCtaData2 = {
    titleText: data.content.edges[0].node.centeredMcta2.title,
    copyText: data.content.edges[0].node.centeredMcta2.content.content,
    imageSrc: data.content.edges[0].node.centeredMcta2.picture.fluid,
    videoSrc: data.content.edges[0].node.centeredMcta2.picture.file.url,
    mediaType: data.content.edges[0].node.centeredMcta2.mediaType,
    mediaCaption: data.content.edges[0].node.centeredMcta2.mediaCaption,
  }
  const marketingCtaData = {
    titleText: data.content.edges[0].node.marketingCta.title,
    copyText: data.content.edges[0].node.marketingCta.content.content,
    imageSrc: data.content.edges[0].node.marketingCta.picture.fluid,
    videoSrc: data.content.edges[0].node.marketingCta.picture.file.url,
    mediaType: data.content.edges[0].node.marketingCta.mediaType,
  }
  const productLineData = {
    titleText: data.content.edges[0].node.productLine.title,
    items: [
      {
        id: data.content.edges[0].node.productLine.productOne.id,
        title: data.content.edges[0].node.productLine.productOne.title,
        imageSrc: data.content.edges[0].node.productLine.productOne.image.fixed,
        buttonText:
          data.content.edges[0].node.productLine.productOne.buttonText,
        color: data.content.edges[0].node.productLine.productOne.color,
        slug: data.content.edges[0].node.productLine.productOne.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLine.productTwo.id,
        title: data.content.edges[0].node.productLine.productTwo.title,
        imageSrc: data.content.edges[0].node.productLine.productTwo.image.fixed,
        buttonText:
          data.content.edges[0].node.productLine.productTwo.buttonText,
        color: data.content.edges[0].node.productLine.productTwo.color,
        slug: data.content.edges[0].node.productLine.productTwo.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLine.productThree.id,
        title: data.content.edges[0].node.productLine.productThree.title,
        imageSrc:
          data.content.edges[0].node.productLine.productThree.image.fixed,
        buttonText:
          data.content.edges[0].node.productLine.productThree.buttonText,
        color: data.content.edges[0].node.productLine.productThree.color,
        slug: data.content.edges[0].node.productLine.productThree.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
    ],
  }

  //provides style for hr line
  const divider = {
    borderTop: "0.5px solid #C3C5C8",
  }

  return (
    <IntlProvider locale={langKey} messages={i18nmessages}>
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={Theme}>
          {/* <AuthWrapper> */}

          <SEO siteMetadata={data.site.siteMetadata} />
          <CartProvider cartId={undefined}>
            <Header slug={pageContext.slug} />
            <HeroBanner
              isProductLine={false}
              static={true}
              slideData={slideData}
            />
            <CenteredMarketingCTA
              static={true}
              reversed
              hideBackground
              {...centeredMarketingCtaData1}
            />
            <MarketingCTA
              static={true}
              reversed
              hideBackground
              {...marketingCtaData}
            />
            <CenteredMarketingCTA
              noTopPadding
              static={true}
              reversed
              hideBackground
              {...centeredMarketingCtaData2}
            />
            <Hidden mdDown>
              <Grid container centered="true">
                <Grid xs={8} lg={12} item centered="true">
                  <hr style={divider} />
                </Grid>
              </Grid>
            </Hidden>
            <ProductLine productLineData={productLineData} langKey={langKey} />
            <Footer
              langKey={langKey}
              bgImgLeft={LeafLeft}
              bgImgRight={LeafRight}
            />
          </CartProvider>

          {/* </AuthWrapper> */}
        </ThemeProvider>
      </React.Fragment>
    </IntlProvider>
  )
}

export default langKey => {
  useEffect(() => {
    navigate(`${langKey.pageContext.locale}/404`)
  }, [])
  return null
}

export const pageQuery = graphql`
  query($locale: String!) {
    content: allContentfulOurSciencePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          centeredMcta1 {
            mediaType
            title
          }
          centeredMcta2 {
            mediaCaption
            mediaType
            title
            picture {
              file {
                url
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
          }
          marketingCta {
            mediaType
            title
            picture {
              file {
                url
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          productLine {
            title
            node_locale
            productOne {
              title
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              color
              slug
            }
            productTwo {
              title
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              color
              slug
            }
            productThree {
              title
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              color
              slug
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
